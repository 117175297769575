import { Directive } from '@angular/core';

@Directive({
  selector: '[appUnwrapTag]'
})
export class UnwrapTagDirective {

  constructor() { }

}
