import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ScriptLoaderService } from "../_services/script-loader.service";
import {Helpers} from "../helpers";
import {Globals} from "../globals";
import {LoginCustom} from "../_helpers/login-custom";
import { Title } from '@angular/platform-browser';

declare var swal : any;
@Component({
  selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
  templateUrl: './auth-admin.component.html',
  styleUrls: ['./auth-admin.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AuthAdminComponent implements OnInit {
    LoginResponse :any = { response : '', mensaje: '', data: '' , aadata : ''};
  	constructor(private _router: Router,private _route: ActivatedRoute,
                private _script: ScriptLoaderService, private urlGlobal : Globals, private titleService: Title) { }

  	ngOnInit() {
        localStorage.clear();
        this.titleService.setTitle('GP | Login');
  		this._script.load('body',
            'assets/vendors/base/vendors.bundle.js',
            'assets/demo/demo2/base/scripts.bundle.js')
          .then(() => {              Helpers.setLoading(false);
              LoginCustom.init();
          });
  	}

  	login() {
        let cuenta = $('#cuenta').val();
        let pass = $('#pass').val();
        this.LoginResponse = this.urlGlobal.gJSON('/Login/loginAdmin?token=XFERDEZ',{"email": cuenta, "password": pass},"POST",false);
        if (this.LoginResponse.response == 'Success'){
                localStorage.setItem('currentUser', JSON.stringify(this.LoginResponse.data));
                this._router.navigate(["/admin/index"]);
        }else{
            swal("Login",this.LoginResponse.message, "error");
        }
    }

}
