import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styles: []
})
export class AsideNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
