import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';
import * as $ from "jquery";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  year_field:Number;
  constructor() {

  }

  ngOnInit() {
      let d = new Date();
      this.year_field = d.getFullYear();
  }

}
