import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProveedoresComponent } from './proveedores.component';
import { Routes, RouterModule  } from '@angular/router'
import { AuthGuard } from '../_guards/auth.guard';
import {AvisosListaModule} from "./pages/avisos-lista/avisos-lista.module";

const routes: Routes = [
	{
		path : '',
        component : ProveedoresComponent,
		children : [
			{
                path: 'proveedores/lista/Subir',
                loadChildren: "./pages/default/lista/lista.module#ListaModule"
            },
            {
                path: 'proveedores/lista/NoValido',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path: 'proveedores/lista/PorAprobar',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path: 'proveedores/lista/PorAutorizar',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path: 'proveedores/lista/Rechazado',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path: 'proveedores/lista/PorPagar',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path: 'proveedores/lista/Pagado',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path : 'proveedores/dashboard',
                // component : './pages/dashboard/dashboard.component',
                loadChildren :'./pages/dashboard/dashboard.module#DashboardModule'
            },
            {
                path : 'proveedores/editar',
                loadChildren: './pages/editar-proveedor/editar-proveedor.module#EditarProveedorModule'
            },
            {
                path: 'proveedores/Validando',
                loadChildren: ".\/pages\/default\/lista\/lista.module#ListaModule"
            },
            {
                path : 'proveedores/pendientes',
                loadChildren : './pages/pendientes/pendientes.module#PendientesModule'
            },
            {
                path : 'proveedores/avisos',
                loadChildren : './pages/avisos-lista/avisos-lista.module#AvisosListaModule'
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
		]
	},
    {
        path:'proveedores/create',
        loadChildren : './pages/create-proveedor/create-proveedor.module#CreateProveedorModule',
    },
	{
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];
@NgModule({
  imports: [
  	RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class ProveedoresRoutingModule { }
