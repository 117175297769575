import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { Helpers } from '../../../helpers';
import {Globals} from "../../../globals";
import * as $ from "jquery";

declare var swal : any;
declare var jQuery : any;
declare let mLayout: any;
@Component({
  selector: 'app-header-nav-proveedor',
  templateUrl: './header-nav-proveedor.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class HeaderNavProveedorComponent implements OnInit, AfterViewInit {
    usuario: String = "";
    NombreComercial: String = "";
    dataUser: any;
    RProveedor: any;
    NumAvisos: number = 0;
    listaAvisos: any;
    _response: any;
    rfc_empresa: String = '';
    muti_sucursal:number = 0;
  	constructor( private _router: Router, private _urlGlobal : Globals) { }

	ngOnInit() {
        let o = this;
        this.dataUser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.dataUser !== null) {
            this.usuario = this.dataUser.Name;
            /*validar si exite esta variable*/
            this.RProveedor = this._urlGlobal.gJSON('/Proveedores/getDataProveedor', {idproveedor: this.dataUser.IdProveedor}, 'POST', false);
            if (this.RProveedor.response == 'Success') {
                let dproveedor = JSON.parse(this.RProveedor.data);
                this.NombreComercial = dproveedor.Razon_Social;
                if (dproveedor.logo != '') {
                    $('#imgProveedor').attr("src", dproveedor.logo);
                }
            }
        }
        this._response = this._urlGlobal.gJSON('/Empresa/obtenerDatosEmpresa', {IdEmpresa: this.dataUser.IdEmpresa}, 'POST', false);
        if (this._response.result == 'success') {
            let dataJson = JSON.parse(this._response.data);
            this.rfc_empresa = dataJson.RfcEmpresa;
            this.muti_sucursal = dataJson.multi_sucursal;
        }
        //botones que realizan las consultas
        let nav = this;
        //this.obtener_estados_doc();
        // valida si exite los datos del path actual
        // if (localStorage.getItem('currentPath') !== null) {
        //     var reloadPath = JSON.parse(localStorage.getItem('currentPath'));
        //     jQuery('ul.m-menu__nav > li.m-menu__item:contains(' + reloadPath.routerActiveLink + ')').filter(function () {
        //         return jQuery(this).text().trim() == reloadPath.routerActiveLink;
        //     }).addClass('m-menu__item--active');
        //
        // }
        // jQuery(document).delegate(".btnMenuFiltroCfdi", "click", function (e) {
        //     e.preventDefault();
        //     e.stopImmediatePropagation();
        //     jQuery('.m-menu__nav > li.m-menu__item').removeClass("m-menu__item--active");
        //     jQuery(this).parent().addClass('m-menu__item--active');
        //     var spanText = jQuery(this).text();
        //     //var curentPathReload = {'urlSegment': nav._router.url, 'routerActiveLink': spanText.trim()};
        //     //localStorage.setItem('currentPath', JSON.stringify(curentPathReload));
        //     if (spanText.trim() !== "Dashboard" && spanText.trim() !== "Pendientes" && spanText.trim() !== "Avisos") {
        //         nav._router.navigate(['proveedores/lista']);
        //         jQuery("#tabla_nominas").DataTable().ajax.reload();
        //     }
        // });
        this.cargarAvisos();
    }
	
	ngAfterViewInit() {
  	    mLayout.initHeader();
	}

    obtener_estados_doc(){
        var response : any = {};
        var listHtml = jQuery('<ul>').addClass("m-nav");
        var listsubnav = jQuery('<ul>').addClass('m-menu__subnav');
        var liHtml = jQuery('<li>').addClass("m-nav__section m-nav__section--first m--hide").text("");
        listHtml.append(liHtml);
        response = this._urlGlobal.gJSON('/EstadosCfdi/obtenerListaEstado', {}, 'POST', false);
        if (response.response == "success") {
            response.data.push({IdEstado:100, Nombre: 'Pendientes', Estatus:1});
            response.data.push({IdEstado:101, Nombre: 'Avisos', Estatus:1});
            jQuery.each(response.data, function(key, value) {
                var lim = jQuery('<li>').addClass("m-nav__item").append(jQuery('<a>').attr('href','').addClass("m-nav__link btnMenuFiltroCfdi").append(jQuery('<span>').addClass("m-nav__link-text").text(value.Nombre)));
                listHtml.append(lim);
                //listsubnav.append(lisubnav);
                let mEstado = '<li class="m-menu__item  " routerLinkActiveOptions="{ exact: false }"   aria-haspopup="true">';
                mEstado += '<a  class="m-menu__link btnMenuFiltroCfdi">';
                mEstado += '<span class="m-menu__item-here"></span>';
                mEstado += '<span class="m-menu__link-text">';
                mEstado += value.Nombre;
                mEstado += '</span>';
                mEstado += '</a>';
                mEstado += '</li>';
                jQuery("#m-menu__nav").append(mEstado);
            });
        }
        listHtml.append(liHtml);
        //jQuery("#themeEstadosDoc").html(listHtml);
        //jQuery("#submenuEstadoheader").html(listsubnav);

    }

    cargarAvisos(){
  	    let o = this;
        o._response = o._urlGlobal.gJSON('/Avisos/obtener_avisos_proveedor_json', o.dataUser,'POST', false);
        if (o._response.result == 'success') {
            o.NumAvisos = o._response.num;
            o.listaAvisos = JSON.parse(o._response.data);
        }
    }

    ver(aviso){
        swal({
            title: 'Aviso',
            text: aviso.Descripcion,
            type: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this._response = this._urlGlobal.gJSON('/Avisos/visto', aviso, 'POST', false);
                if (this._response.result == 'success') {
                    this.cargarAvisos();
                } else {
                    swal("Aviso", this._response.mensaje, "error");
                }
            }
        });
    }
}