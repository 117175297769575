import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthAdminComponent} from "./auth-admin/auth-admin.component";
import {LogoutComponent} from "./proveedores/pages/logout/logout.component";

const routes: Routes = [
	{ path: 'logout', component: LogoutComponent },
	{ path: 'prueba', loadChildren : './prueba/prueba.module#PruebaModule'},
	{ path: 'authA', component : AuthAdminComponent },
	{ path: 'auth2', loadChildren : './auth2/auth2.module#Auth2Module' },
	{ path: '', redirectTo: '/principal', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }