import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AuthAdminComponent } from './auth-admin.component';

const routes: Routes = [
	{ path: '', component : AuthAdminComponent }
];
  	
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule, ],
  declarations: []
})
export class AuthAdminRoutingModule { }
