import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { Helpers } from '../../../helpers';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {

  constructor(private _router : Router ) { }

  ngOnInit() {
  	Helpers.setLoading(true);
    // reset login status
    localStorage.removeItem('currentPath');
  	localStorage.removeItem('currentUser');
  	this._router.navigate(['auth2']);
  }

}
