import { Injectable } from '@angular/core';
import * as $ from "jquery";
import {Helpers} from "./helpers";
// import { Http, Response } from "@angular/http";
// import { Http, Headers, Response } from '@angular/http';
/*import jQuery from 'node_modules/jquery';*/

/*
* declaromos los componetes a usar
*/
// declare var jQuery: any;
// declare var $: any;

@Injectable()
export class Globals {

  	role: string = 'test';
    //public HOST: string = "http://localhost:8080/validador-proveedores/api";
    //public HOST: string = "https://ng.internalx.acpdelsureste.net/api";
    public HOST: string = "https://apis.clusterapis.acpdelsureste.net/";

  	constructor(){

  	}

  	public gJSON(url, data, type, cache,before = false){
  		var rValue = "";
  		if(typeof(cache) != "undefined"){
			cache = cache;
		}else{
			cache = false;
		}
		if(typeof(type) != "undefined"){
			type = type;
		}else{
			type = "GET";
		}
		
		if(typeof(url) == "undefined"){
			url = "/cpp/test";	
		}
        var usuario = localStorage.getItem('currentUser');
  		if (usuario != null) {
            if (usuario.indexOf('{') != -1) {
                let user = JSON.parse(usuario);
                let extras = {'id_proveedor': user.idproveedor};
                $.extend(true, data, extras);
            }
        }
		// extras = {codigo: $.cookie('codigo'), lang:$.cookie('lang'), user_id : $.cookie("user")};
        this.HOST = this.HOST.replace(" ", "");
		$.ajax({
			type: type,
			url: this.HOST + url,
			dataType: 'json',
			data: data,
			async: false,
			cache: cache,
			/*headers: {
		        'Access-Control-Allow-Headers' :'Origin, X-Requested-With, Content-Type, Accept, Authorization',
		        "Host-Origin-App":window.location.origin,
		    }*/
			/*beforeSend:  '',	*/
		}).done(function( json ) {
			rValue = json;
            Helpers.setLoading(false);
		});		
		return rValue;
  	}

}