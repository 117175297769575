import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from '../helpers';
import { ScriptLoaderService } from '../_services/script-loader.service';


/*@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styles: []
})*/

declare let mApp: any;
declare let mUtil: any;
@Component({
    selector: ".m-grid.m-grid--hor.m-grid--root.m-page",
    templateUrl: "./proveedores.component.html",
    encapsulation: ViewEncapsulation.None,
})

export class ProveedoresComponent implements OnInit {

  constructor(private _script: ScriptLoaderService, private _router: Router) { }

  ngOnInit() {
  	    this._script.load('body', 
           'assets/vendors/base/vendors.bundle.js', 
           'assets/demo/demo2/base/scripts.bundle.js', 
          /* 'assets/plugins/jQuery-ui/jquery-ui-1.12.1.custom.min.js', */
           'assets/plugins/uploadify-html/jquery.uploadifive.min.js', 
           'assets/plugins/DataTable/datatables.min.js',
           'assets/plugins/DataTable/jquery.dataTables.min.js',
           'assets/plugins/DataTable/datatables.bootstrap.js',
           )            .then(result => {
                Helpers.setLoading(false);
            });
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                (<any>mApp).scrollTop();
                Helpers.setLoading(true);
                // hide visible popover
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // init required js
                (<any>mApp).init();
                (<any>mUtil).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                let animation = 'm-animate-fade-in-up';
                $('.m-wrapper').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e) {
                    $('.m-wrapper').removeClass(animation);
                }).removeClass(animation).addClass(animation);
            }
        });
  }

}
