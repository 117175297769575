declare var jQuery : any;

export class LoginCustom {
	static mensajesObligatorios(){
		jQuery.extend(jQuery.validator.messages, {
	        required: "Este campo es obligatorio.",
	        email: "Por favor, introduce una dirección de correo electrónico válida.",
	    });
	}

	static handleSignInFormSubmit() {
        jQuery('#m_login_signin_submit').click(function(e) {
            let form = jQuery(this).closest('form');
            form.validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                    },
                    password: {
                        required: true
                    }
                },
            });
            if (!form.valid()) {
                e.preventDefault();
                return;
            }
        });
    }
	static init() {
		LoginCustom.mensajesObligatorios();
		LoginCustom.handleSignInFormSubmit();
    }
}
