import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdministradorComponent } from "./administrador.component";
import {ComprobantesModule} from "./pages/comprobantes/comprobantes.module";
import {ProveedoresListaModule} from "./pages/proveedores-lista/proveedores-lista.module";
import {EstadosListaModule} from "./pages/estados-lista/estados-lista.module";
import {PendientesListaModule} from "./pages/pendientes-lista/pendientes-lista.module";
import { AuthGuard } from '../_guards/auth.guard';
import {ComprobantesPeModule} from "./pages/comprobantes-pe/comprobantes-pe.module";

const routes: Routes = [
    {
        path : '',
        component : AdministradorComponent,
        children : [
            {
                "path": "admin/index",
                "loadChildren": ".\/index-admin\/index-admin.module#IndexAdminModule"
            },
            {
                "path": "admin/comprobantes/I",
                "loadChildren": "./pages/comprobantes/comprobantes.module#ComprobantesModule"
            },
            {
                "path": "admin/comprobantes/P",
                "loadChildren": "./pages/comprobantes-pe/comprobantes-pe.module#ComprobantesPeModule"
            },
            {
                "path": "admin/comprobantes/E",
                "loadChildren": "./pages/comprobantes-pe/comprobantes-pe.module#ComprobantesPeModule"
            },
            {
                "path": "admin/proveedores",
                "loadChildren": "./pages/proveedores-lista/proveedores-lista.module#ProveedoresListaModule"
            },
            {
                "path": "admin/Pendientes",
                "loadChildren": "./pages/pendientes-lista/pendientes-lista.module#PendientesListaModule"
            },
            {
                path : "admin/avisos",
                loadChildren : "./pages/mensajes/mensajes.module#MensajesModule"
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
        ]
    },
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
  imports: [
      RouterModule.forChild(routes),
    CommonModule
  ],
    exports: [RouterModule],
  declarations: []
})
export class AdministradorRoutingModule { }
