import { Directive } from '@angular/core';

@Directive({
  selector: '[appHrefPreventDefault]'
})
export class HrefPreventDefaultDirective {

  constructor() { }

}
