import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { ThemeComponent } from './theme/theme.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from "./_services/script-loader.service";
import { ThemeRoutingModule } from "./theme/theme-routing.module";
import { GlobalErrorHandler } from "./_services/error-handler.service";
import { ProveedoresRoutingModule } from './proveedores/proveedores-routing.module';
import { ProveedoresComponent } from './proveedores/proveedores.component';

import {Globals} from './globals';
import { PrincipalRoutingModule } from './principal/principal-routing.module';
import { PrincipalComponent } from './principal/principal.component';
import { AuthAdminModule } from './auth-admin/auth-admin.module';
import { AdministradorRoutingModule } from './administrador/administrador-routing.module';
import { AdministradorComponent } from './administrador/administrador.component';
import { LogoutComponent } from './proveedores/pages/logout/logout.component';

@NgModule({
    declarations: [
        ThemeComponent,
        AppComponent,
        ProveedoresComponent,
        PrincipalComponent,
        AdministradorComponent,
        LogoutComponent,
    ],
    imports: [
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        ProveedoresRoutingModule,
        PrincipalRoutingModule,
        AuthAdminModule,
        AdministradorRoutingModule
    ],
    providers: [Globals, ScriptLoaderService, { provide: ErrorHandler, useClass: GlobalErrorHandler }],
    bootstrap: [AppComponent]
})
export class AppModule { }