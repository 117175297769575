import { NgModule } from '@angular/core';
import { ThemeComponent } from './theme.component';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

const routes: Routes = [
	{
		"path": "",
    	"component": ThemeComponent,
    	"children": [
    		{
                "path": "index",
                "loadChildren": ".\/pages\/aside-left-display-disabled\/index\/index.module#IndexModule"
            },
            {
                "path": "",
                "redirectTo": "index",
                "pathMatch": "full"
            }
    	]
	},
    {
        "path": "**",
        "redirectTo": "404",
        "pathMatch": "full"
    }
];

@NgModule({
  	imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  	declarations: []
})
export class ThemeRoutingModule { }
