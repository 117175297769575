import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Helpers } from "./helpers";
import { Globals } from './globals';
@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: [],
  encapsulation : ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'app';
    globalBodyClass = 'm-page--loading-non-block m-page--wide m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

constructor(private _router: Router, private globals: Globals) {
    }
    ngOnInit() {
      //console.log(this.globals.gJSON("/Documentos/obtener_documentos_json2", "","POST",false));
        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);

            }
            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });
    }
}