import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrincipalComponent } from './principal.component';
import { Routes, RouterModule  } from '@angular/router'

const routes: Routes = [
 	{path : 'principal' , component : PrincipalComponent },
];

@NgModule({
  imports: [
  	RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class PrincipalRoutingModule { }
