import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import * as $ from "jquery";
import {Router} from "@angular/router";
import {Globals} from "../../../globals";

declare var jQuery : any;
declare let mLayout: any;
@Component({
  selector: 'app-header-nav-admin',
  templateUrl: './header-nav-admin.component.html',
  styleUrls: ['./header-nav-admin.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderNavAdminComponent implements OnInit, AfterViewInit {
    usuario: String = "";
    data: any;
    response: any;
    rfc_empresa: String = '';
    constructor(private _router: Router,  private _urlGlobal : Globals) {
    }

    ngOnInit() {
        this.data = JSON.parse(localStorage.getItem('currentUser'));
       this.usuario = this.data.Name;
        this.response = this._urlGlobal.gJSON('/Empresa/obtenerDatosEmpresa', {IdEmpresa: this.data.IdEmpresa}, 'POST', false);
        if (this.response.result == 'success') {
            let dataJson = JSON.parse(this.response.data);
            this.rfc_empresa = dataJson.RfcEmpresa;
        }
    }

    ngAfterViewInit() {
        mLayout.initHeader();
    }

}
