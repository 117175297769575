import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Helpers } from '../../../helpers';
import { ScriptLoaderService } from '../../../_services/script-loader.service';


@Component({
    selector: ".m-grid__item.m-grid__item--fluid.m-grid.m-grid--hor-desktop.m-grid--desktop.m-body",
    templateUrl: "./aside-left-display-disabled.component.html",
    encapsulation: ViewEncapsulation.None,
})
/*@Component({
  selector: 'app-aside-left-display-disabled',
  templateUrl: './aside-left-display-disabled.component.html',
  styles: []
})*/
export class AsideLeftDisplayDisabledComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
