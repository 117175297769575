import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { BaseRequestOptions, HttpModule } from "@angular/http";
import { MockBackend } from "@angular/http/testing";

import { AuthAdminRoutingModule } from './auth-admin-routing.module';
import { AuthAdminComponent } from './auth-admin.component';

@NgModule({
	imports: [
    	CommonModule,
        FormsModule,
        HttpModule,
        AuthAdminRoutingModule,
  	],
  declarations: [AuthAdminComponent]
})
export class AuthAdminModule { }
